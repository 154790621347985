import axios from "axios";

const api = axios.create({
	baseURL: "https://chatapi.starsistemas.com.br",//process.env.REACT_APP_BACKEND_URL,
	withCredentials: true,
});

export const openApi = axios.create({
	baseURL: "https://chatapi.starsistemas.com.br",//process.env.REACT_APP_BACKEND_URL
});

export default api;
