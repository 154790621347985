// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Adicione as regras CSS para ocultar os ícones */
.event-container {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .delete-icon,
  .edit-icon {
    opacity: 0;
    transition: opacity 0.3s;
    margin-left: 5px; /* Adicione margem à esquerda */
    z-index: 1; /* Garante que os ícones apareçam sobre o evento */
  }
  
  .event-container:hover .delete-icon,
  .event-container:hover .edit-icon {
    opacity: 1;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/Schedules/Schedules.css"],"names":[],"mappings":"AAAA,kDAAkD;AAClD;IACI,kBAAkB;IAClB,eAAe;IACf,aAAa;IACb,mBAAmB;IACnB,uBAAuB;EACzB;;EAEA;;IAEE,UAAU;IACV,wBAAwB;IACxB,gBAAgB,EAAE,+BAA+B;IACjD,UAAU,EAAE,kDAAkD;EAChE;;EAEA;;IAEE,UAAU;EACZ","sourcesContent":["/* Adicione as regras CSS para ocultar os ícones */\n.event-container {\n    position: relative;\n    cursor: pointer;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n  \n  .delete-icon,\n  .edit-icon {\n    opacity: 0;\n    transition: opacity 0.3s;\n    margin-left: 5px; /* Adicione margem à esquerda */\n    z-index: 1; /* Garante que os ícones apareçam sobre o evento */\n  }\n  \n  .event-container:hover .delete-icon,\n  .event-container:hover .edit-icon {\n    opacity: 1;\n  }\n  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
