// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.smooth-dnd-container {

    min-height: 75vh;
  
    max-height: 75vh;
  
  
  
  
  }
  
  
  
  
  .react-trello-lane {
  
    width: 350px;
  
    background-color: #f0eeee !important;
  
  }
  
  
  
  
  
  
  
  .react-trello-card {
  
    width: 100% !important;
  
    max-width: 100% !important;
  
    min-width: 100% !important;
  
  }
  
  
  
  
  .react-trello-board {
  
    min-height: 90vh;
  
    max-height: 90vh;
  
  }
  
  
  
  
  
  
  
  .smooth-dnd-container .vertical {
  
    overflow: auto;
  
    scrollbar-width: thin;
  
    width: 330px;
  
    scrollbar-color: rgb(146, 146, 146);
  
    /* Cor da barra de rolagem */
  
  }
  
  
  
  
  section>header>span {
  
    color: #b207f5;
  
    background-color: #fff;
  
    width: 100% !important;
  
    padding: 10px;
  
    text-align: center;
  
    font-weight: 400 !important;
  
    font-size: 25px !important;
  
    border-radius: 10px;
  
  }`, "",{"version":3,"sources":["webpack://./src/pages/Kanban/style.css"],"names":[],"mappings":"AAAA;;IAEI,gBAAgB;;IAEhB,gBAAgB;;;;;EAKlB;;;;;EAKA;;IAEE,YAAY;;IAEZ,oCAAoC;;EAEtC;;;;;;;;EAQA;;IAEE,sBAAsB;;IAEtB,0BAA0B;;IAE1B,0BAA0B;;EAE5B;;;;;EAKA;;IAEE,gBAAgB;;IAEhB,gBAAgB;;EAElB;;;;;;;;EAQA;;IAEE,cAAc;;IAEd,qBAAqB;;IAErB,YAAY;;IAEZ,mCAAmC;;IAEnC,4BAA4B;;EAE9B;;;;;EAKA;;IAEE,cAAc;;IAEd,sBAAsB;;IAEtB,sBAAsB;;IAEtB,aAAa;;IAEb,kBAAkB;;IAElB,2BAA2B;;IAE3B,0BAA0B;;IAE1B,mBAAmB;;EAErB","sourcesContent":[".smooth-dnd-container {\n\n    min-height: 75vh;\n  \n    max-height: 75vh;\n  \n  \n  \n  \n  }\n  \n  \n  \n  \n  .react-trello-lane {\n  \n    width: 350px;\n  \n    background-color: #f0eeee !important;\n  \n  }\n  \n  \n  \n  \n  \n  \n  \n  .react-trello-card {\n  \n    width: 100% !important;\n  \n    max-width: 100% !important;\n  \n    min-width: 100% !important;\n  \n  }\n  \n  \n  \n  \n  .react-trello-board {\n  \n    min-height: 90vh;\n  \n    max-height: 90vh;\n  \n  }\n  \n  \n  \n  \n  \n  \n  \n  .smooth-dnd-container .vertical {\n  \n    overflow: auto;\n  \n    scrollbar-width: thin;\n  \n    width: 330px;\n  \n    scrollbar-color: rgb(146, 146, 146);\n  \n    /* Cor da barra de rolagem */\n  \n  }\n  \n  \n  \n  \n  section>header>span {\n  \n    color: #b207f5;\n  \n    background-color: #fff;\n  \n    width: 100% !important;\n  \n    padding: 10px;\n  \n    text-align: center;\n  \n    font-weight: 400 !important;\n  \n    font-size: 25px !important;\n  \n    border-radius: 10px;\n  \n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
