const messages = {
    fr: {
      translations: {
        signup: {
          title: "Inscription",
          toasts: {
            success: "Utilisateur créé avec succès ! Connectez-vous maintenant !",
            fail: "Erreur lors de la création de l'utilisateur. Vérifiez les données saisies.",
          },
          form: {
            name: "Nom",
            email: "Courriel",
            password: "Mot de passe",
          },
          buttons: {
            submit: "S'inscrire",
            login: "Vous avez déjà un compte ? Connectez-vous !",
            returlogin: "Retour au menu principal",
            send: "Envoyer un courriel",
          },
        },
        resetpswd:{
          title2: "Réinitialiser le mot de passe",
          toasts: {
            success: "Vérifiez votre boîte de réception pour continuer !",
            resetsucess: "Mot de passe réinitialisé avec succès, retournez à l'écran de connexion pour accéder à la plateforme !",
            fail: "Erreur lors de la réinitialisation du mot de passe. Vérifiez le jeton fourni.",
          },
        },
        login: {
          title: "Connexion",
          form: {
            email: "Courriel",
            password: "Mot de passe",
          },
          buttons: {
            submit: "Se connecter",
            register: "Pas de compte ? Inscrivez-vous !",
          },
        },
        plans: {
          form: {
            name: "Nom",
            users: "Utilisateurs",
            connections: "Connexions",
            campaigns: "Campagnes",
            schedules: "Planifications",
            email: "Courriel",
            chat: "Chat interne",
            api: "API externe",
            kanban: "Kanban",
            integrations: "Intégrations",
            enabled: "Activées",
            disabled: "Désactivées",
            clear: "Annuler",
            delete: "Supprimer",
            save: "Sauvegarder",
            yes: "Oui",
            no: "Non",
            money: "€",
          },
        },
        companies: {
          title: "Enregistrer une entreprise",
          form: {
            name: "Nom de l'entreprise",
            plan: "Plan",
            token: "Jeton",
            submit: "Enregistrer",
            success: "Entreprise créée avec succès !",
          },
        },
        auth: {
          toasts: {
            success: "Connexion réussie !",
          },
          token: "Jeton",
        },
        dashboard: {
          charts: {
            perDay: {
              title: "Soins aujourd'hui : ",
            },
            filters: {
              startDate: "Date de début",
              endDate: "Date de fin",
              periodText: "Période",
              periodOptions: {
                input: "Sélectionnez la période souhaitée",
                zero: "Aucune période sélectionnée",
                three: "Trois derniers jours",
                seven: "Sept derniers jours",
                fifteen: "Quinze derniers jours",
                thirty: "Trente derniers jours",
                sixty: "Soixante derniers jours",
                ninety: "Quatre-vingt-dix derniers jours"
              },
              duedate: "Date d'échéance",
              filtertype: {
                  title: "Type de filtre",
                  valueA: "Filtre par date",
                  valueB: "Filtre par période",
                  helperText: "Sélectionnez la période souhaitée",
              },
            },
          },
          cards: {
            attdPendants: "Soins en attente",
            attdHappening: "Soins en cours",
            attdPerformed: "Soins réalisés",
            leads: "Leads",
            mtofService: "T.M. de soin",
            mtofwaiting: "T.M. d'attente",
          },
          stadis: {
            name: "Nom",
            calif: "Évaluations",
            timemedia: "T.M. de chat",
            statuschat: "Statut (Actuel)",
          },
        },
        kanban: {
          inopen: "En attente",
        },
        todo: {
          newtask: "Nouvelle tâche",
          buttons: {
            add: "Ajouter",
            edit: "Enregistrer",
          },
        },
        connections: {
          title: "Connexions",
          toasts: {
            deleted: "Connexion WhatsApp supprimée avec succès !",
          },
          confirmationModal: {
            deleteTitle: "Supprimer",
            deleteMessage: "Êtes-vous sûr ? Cette action ne peut pas être annulée.",
            disconnectTitle: "Déconnecter",
            disconnectMessage: "Êtes-vous sûr ? Vous devrez à nouveau scanner le code QR.",
          },
          buttons: {
            add: "Ajouter WhatsApp",
            disconnect: "Déconnecter",
            tryAgain: "Réessayer",
            qrcode: "QR CODE",
            newQr: "Nouveau QR CODE",
            connecting: "Connexion en cours",
          },
          toolTips: {
            disconnected: {
              title: "Impossible de démarrer la session WhatsApp",
              content: "Assurez-vous que votre téléphone portable est connecté à Internet et réessayez, ou demandez un nouveau code QR.",
            },
            qrcode: {
              title: "En attente de lecture du code QR",
              content: "Cliquez sur le bouton 'QR CODE' et scannez le code QR avec votre téléphone portable pour démarrer la session.",
            },
            connected: {
              title: "Connexion établie !",
            },
            timeout: {
              title: "La connexion avec le téléphone portable a été perdue",
              content: "Assurez-vous que votre téléphone portable est connecté à Internet et que WhatsApp est ouvert, ou cliquez sur le bouton 'Déconnecter' pour obtenir un nouveau code QR.",
            },
          },
          table: {
            name: "Nom",
            status: "Statut",
            lastUpdate: "Dernière mise à jour",
            default: "Par défaut",
            actions: "Actions",
            session: "Session",
          },
        },
        whatsappModal: {
          title: {
            add: "Ajouter WhatsApp",
            edit: "Modifier WhatsApp",
          },
          form: {
            name: "Nom",
            default: "Par défaut",
            sendIdQueue: "File d'attente",
            timeSendQueue: "Rediriger vers la file d'attente en X minutes",
            queueRedirection: "Redirection de file d'attente",
            queueRedirectionDesc: "Sélectionnez une file d'attente pour rediriger les contacts qui n'ont pas de file d'attente assignée",
            prompt: "Invite",
            maxUseBotQueues: "Envoyer le bot X fois",
            timeUseBotQueues: "Intervalle en minutes entre l'envoi du bot",
            expiresTicket: "Fermer les chats ouverts après X minutes",
            expiresInactiveMessage: "Message de clôture pour inactivité",
            geratoken: "Générer un jeton",
          },
          buttons: {
            okAdd: "Ajouter",
            okEdit: "Sauvegarder",
            cancel: "Annuler",
          },
          success: "WhatsApp sauvegardé avec succès.",
          queueTimeError: "Ce champ ne peut pas être vide. Utilisez 0 pour désactiver.",
        },
        qrCode: {
          message: "Scannez le QR Code pour démarrer la session.",
          title: "Utilisez AutoAtende avec votre WhatsApp :",
          firstline: "Ouvrez WhatsApp sur votre téléphone",
          secondline: {
            touch: "Appuyez sur Plus d'options sur Android",
            orsetting: "ou sur Réglages",
            iphone: "sur iPhone",
          },
          thirdline: "Appuyez sur Appareils connectés, puis sur Connecter un appareil",
          fourthline: "Pointez votre téléphone vers cet écran pour capturer le code QR",
        },
        contacts: {
          title: "Contacts",
          toasts: {
            deleted: "Contact supprimé avec succès !",
          },
          searchPlaceholder: "Rechercher...",
          confirmationModal: {
            deleteTitle: "Supprimer",
            deleteAllTitle: "Supprimer tous",
            importTitlte: "Importer des contacts",
            deleteMessage: "Êtes-vous sûr de vouloir supprimer ce contact ? Tous les tickets associés seront perdus.",
            deleteAllMessage: "Êtes-vous sûr de vouloir supprimer tous les contacts ? Tous les tickets associés seront perdus.",
            importMessage: "Voulez-vous importer tous les contacts de votre téléphone ?",
          },
          buttons: {
            import: "Importer des contacts",
            add: "Ajouter un contact",
            delete: "Supprimer tous les contacts",
            export: "Exporter les contacts",
          },
          table: {
            name: "Nom",
            whatsapp: "WhatsApp",
            email: "Courriel",
            actions: "Actions",
          },
        },
        queueIntegrationModal: {
          title: {
            add: "Ajouter un projet",
            edit: "Modifier un projet",
          },
          form: {
            id: "ID",
            type: "Type",
            name: "Nom",
            projectName: "Nom du projet",
            language: "Langue",
            jsonContent: "Contenu JSON",
            urlN8N: "URL",
            typebotSlug: "Typebot - Slug",
            typebotExpires: "Temps en minutes pour expirer une conversation",
            typebotKeywordFinish: "Mot pour terminer le ticket",
            typebotKeywordRestart: "Mot pour redémarrer le flux",
            typebotRestartMessage: "Message au redémarrage de la conversation",
            typebotUnknownMessage: "Message d'option invalide",
            typebotDelayMessage: "Intervalle (ms) entre les messages",
          },
          buttons: {
            okAdd: "Ajouter",
            okEdit: "Enregistrer",
            cancel: "Annuler",
            test: "Tester le bot",
          },
          messages: {
            testSuccess: "Intégration testée avec succès",
            addSuccess: "Intégration ajoutée avec succès",
            editSuccess: "Intégration modifiée avec succès",
          },
        },
        promptModal: {
          form: {
            name: "Nom",
            prompt: "Invite",
            voice: "Voix",
            max_tokens: "Nombre maximum de jetons dans la réponse",
            temperature: "Température",
            apikey: "Clé API",
            max_messages: "Nombre maximum de messages dans l'historique",
            voiceKey: "Clé de l'API vocale",
            voiceRegion: "Région vocale",
          },
          success: "Invite sauvegardée avec succès",
          title: {
            add: "Ajouter une invite",
            edit: "Modifier une invite",
          },
          buttons: {
            okAdd: "Ajouter",
            okEdit: "Enregistrer",
            cancel: "Annuler",
          },
        },
        prompts: {
          title: "Invites",
          table: {
            name: "Nom",
            queue: "Secteur/File d'attente",
            max_tokens: "Nombre maximum de jetons de réponse",
            actions: "Actions",
          },
          confirmationModal: {
            deleteTitle: "Supprimer",
            deleteMessage: "Êtes-vous sûr ? Cette action ne peut pas être annulée !",
          },
          buttons: {
            add: "Ajouter une invite",
          },
        },
        contactModal: {
          title: {
            add: "Ajouter un contact",
            edit: "Modifier un contact",
          },
          form: {
            mainInfo: "Détails du contact",
            extraInfo: "Informations supplémentaires",
            name: "Nom",
            number: "Numéro de Whatsapp",
            email: "Courriel",
            extraName: "Nom du champ",
            whatsapp: "Connexion d'origine :",
            extraValue: "Valeur",
            disableBot: "Désactiver le chatbot pour ce contact",
          },
          buttons: {
            addExtraInfo: "Ajouter des informations",
            okAdd: "Ajouter",
            okEdit: "Enregistrer",
            cancel: "Annuler",
            greetingMessage: "Message de bienvenue",
            complationMessage: "Message de fin",
            outOfHoursMessage: "Message hors heures de travail",
            ratingMessage: "Message d'évaluation",
            token: "Jeton",
            orderQueue: "Ordre de la file d'attente (Bot)",
            integrationId: "ID d'intégration",
          },
          success: "Contact sauvegardé avec succès.",
        },
        queueModal: {
          title: {
            add: "Ajouter une file d'attente",
            edit: "Modifier une file d'attente",
          },
          form: {
            name: "Nom",
            color: "Couleur",
            greetingMessage: "Message de salutation",
          },
          buttons: {
            okAdd: "Ajouter",
            okEdit: "Sauvegarder",
            cancel: "Annuler",
            attach: "Joindre un fichier",
          },
        },
        userModal: {
          title: {
            add: "Ajouter un utilisateur",
            edit: "Modifier un utilisateur",
          },
          form: {
            name: "Nom",
            email: "Courriel",
            password: "Mot de passe",
            profile: "Profil",
            startWork: "Début du travail",
            endWork: "Fin du travail",
            isTricked: "Voir les contacts",
            allTicket: "Voir les appels sans file d'attente",
            spy: "Espionner la conversation",
            enabled: "Activé",
            disabled: "Désactivé",
          },
          buttons: {
            okAdd: "Ajouter",
            okEdit: "Enregistrer",
            cancel: "Annuler",
          },
          success: "Utilisateur sauvegardé avec succès.",
        },
        chat: {
          noTicketMessage: "Sélectionnez un ticket pour commencer à discuter.",
        },
        uploads: {
          titles: {
            titleUploadMsgDragDrop: "FAITES GLISSER ET DÉPOSEZ LES FICHIERS DANS LE CHAMP CI-DESSOUS",
            titleFileList: "Liste de fichier(s)"
          },
        },
        scheduleModal: {
          title: {
            add: "Nouvelle programmation",
            edit: "Modifier la programmation",
          },
          form: {
            body: "Message",
            contact: "Contact",
            sendAt: "Date de programmation",
            sentAt: "Date d'envoi",
          },
          buttons: {
            okAdd: "Ajouter",
            okEdit: "Enregistrer",
            cancel: "Annuler",
          },
          success: "Programmation sauvegardée avec succès.",
        },
        tagModal: {
          title: {
            add: "Nouvelle étiquette",
            edit: "Modifier l'étiquette",
            addKanban: "Nouvelle étiquette",
            editKanban: "Modifier l'étiquette",
          },
          form: {
            name: "Nom",
            color: "Couleur",
          },
          buttons: {
            okAdd: "Ajouter",
            okEdit: "Sauvegarder",
            cancel: "Annuler",
          },
          success: "Étiquette sauvegardée avec succès.",
        },
        uploads: {
          titles: {
            titleUploadMsgDragDrop: "FAITES GLISSER ET DÉPOSEZ LES FICHIERS DANS LE CHAMP CI-DESSOUS",
            titleFileList: "Liste de fichier(s)"
          },
        },
        ticketsManager: {
          buttons: {
            newTicket: "Nouveau",
          },
        },
        ticketsQueueSelect: {
          placeholder: "Files d'attente",
        },
        tickets: {
          toasts: {
            deleted: "Le ticket sur lequel vous étiez a été supprimé.",
          },
          notification: {
            message: "Message de",
          },
          tabs: {
            open: { title: "Ouverts" },
            group: { title: "Groupes" },
            private: { title: "Privés" },
            closed: { title: "Résolus" },
            search: { title: "Recherche" },
          },
          search: {
            placeholder: "Rechercher des tickets et des messages",
          },
          buttons: {
            showAll: "Tous",
          },
        },
        transferTicketModal: {
          title: "Transférer le ticket",
          fieldLabel: "Tapez pour rechercher des utilisateurs",
          comments: "Commentaires",
          fieldQueueLabel: "Transférer à la file d'attente",
          fieldQueuePlaceholder: "Sélectionnez une file d'attente",
          fieldConnectionSelect: "Sélectionnez une connexion",
          noOptions: "Aucun utilisateur trouvé avec ce nom",
          buttons: {
            ok: "Transférer",
            cancel: "Annuler",
          },
        },
        ticketsList: {
          pendingHeader: "En attente",
          assignedHeader: "En cours",
          noTicketsTitle: "Rien ici !",
          noTicketsMessage: "Aucun ticket trouvé avec ce statut ou terme de recherche",
          buttons: {
            accept: "Accepter",
            closed: "Fermer",
            reopen: "Rouvrir",
          },
        },
        newTicketModal: {
          title: "Créer un ticket",
          fieldLabel: "Tapez pour rechercher le contact",
          add: "Ajouter",
          buttons: {
            ok: "Sauvegarder",
            cancel: "Annuler",
          },
          queue: "Sélectionnez une file d'attente",
          conn: "Sélectionnez une connexion",
        },
        ticketdetails: {
          iconspy: "Espionner le chat",
          iconacept: "Accepter le chat",
          iconreturn: "Retourner à la file d'attente",
          iconstatus: "SANS FILE D'ATTENTE",
        },
        optionsPage: {
          calif: "Évaluations",
          expedient: "Gestion des horaires",
          ignore: "Ignorer les messages de groupes",
          aceptcall: "Accepter l'appel",
          typechatbot: "Type de chatbot",
          sendanun: "Envoyer un salut en acceptant le ticket",
          sendagent: "Envoyer un message de transfert de file d'attente/agent",
          createuser: "Création d'utilisateurs",
          integra: "INTÉGRATIONS",
          buttons: {
            off: "Désactivé",
            on: "Activé",
            offs: "Désactivées",
            ons: "Activées",
            quee: "File d'attente",
            partner: "Entreprise",
            act: "Activé",
            desact: "Désactivé",
            callok: "Accepter",
            calldeny: "Ne pas accepter",
          },
        },
        daysweek: {
          day1: "Lundi",
          day2: "Mardi",
          day3: "Mercredi",
          day4: "Jeudi",
          day5: "Vendredi",
          day6: "Samedi",
          day7: "Dimanche",
          save: "ENREGISTRER",
        },
        mainDrawer: {
          listTitle: {
            service: "Tickets",
            management: "Gestion",
            administration: "Administration",
          },
          listItems: {
            dashboard: "Tableau de bord",
            connections: "Connexions",
            tickets: "Tickets",
            tasks: "Tâches",
            quickMessages: "Réponses rapides",
            contacts: "Contacts",
            queues: "Files d'attente et Chatbot",
            tags: "Étiquettes",
            kanban: "Kanban",
            email: "Email",
            users: "Utilisateurs",
            settings: "Paramètres",
            helps: "Aide",
            messagesAPI: "API",
            schedules: "Programmations",
            campaigns: {
              menu: "Campagnes",
              listing: "Liste",
              contactList: "Liste de contacts",
              config: "Configurations",
            },
            annoucements: "Annonces",
            chats: "Chat interne",
            financeiro: "Financier",
            files: "Liste de fichiers",
            prompts: "Open.Ai",
            queueIntegration: "Intégrations de file d'attente",
            companies: "Entreprises",
            exit: "Sortir",
          },
          appBar: {
            user: {
              profile: "Profil",
              logout: "Se déconnecter",
            },
            greetings: {
              one: "Bonjour",
              two: "Bienvenue à",
              three: "Actif jusqu'à",
            },
            i18n: {
              language: "Français",
              language_short: "FR",
            },
            notRegister: "Aucun WhatsApp connecté.",
          },
          kanban: {
            subMenus: {
              list: "Lanes",
              tags: "Tags",
            },
          },
        },
        email: {
          subMenus: {
            send: "Envoyer un email",
            sent: "Emails envoyés",
            schedule: "Planifier l'envoi",
            scheduled: "Envois planifiés",
          },
        },
        queueIntegration: {
          title: "Intégrations",
          table: {
            id: "ID",
            type: "Type",
            name: "Nom",
            projectName: "Nom du projet",
            language: "Langue",
            lastUpdate: "Dernière mise à jour",
            actions: "Actions",
          },
          buttons: {
            add: "Ajouter un projet",
          },
          searchPlaceholder: "Rechercher...",
          confirmationModal: {
            deleteTitle: "Supprimer",
            deleteMessage: "Êtes-vous sûr ? Cette action ne peut pas être annulée ! et sera supprimée des files d'attente et des connexions liées",
          },
        },
        files: {
          title: "Liste de fichiers",
          table: {
            name: "Nom",
            contacts: "Contacts",
            actions: "Action",
          },
          toasts: {
            deleted: "Liste supprimée avec succès !",
            deletedAll: "Toutes les listes ont été supprimées avec succès !",
          },
          buttons: {
            add: "Ajouter",
            deleteAll: "Supprimer tous",
          },
          confirmationModal: {
            deleteTitle: "Supprimer",
            deleteAllTitle: "Supprimer tous",
            deleteMessage: "Êtes-vous sûr de vouloir supprimer cette liste ?",
            deleteAllMessage: "Êtes-vous sûr de vouloir supprimer toutes les listes ?",
          },
        },
        messagesAPI: {
          title: "API",
          doc: "Documentation pour l'envoi de messages :",
          formMethod: "Méthode d'envoi :",
          textMessage: {
            number: "Numéro",
            body: "Message",
            token: "Jeton enregistré",
          },
          mediaMessage: {
            number: "Numéro",
            body: "Nom du fichier",
            media: "Fichier",
            token: "Jeton enregistré",
          },
          buttons: {
            submit: "Envoyer",
          },
          helpTexts: {
            textMsg: {
              title: "Message texte",
              info: "Voici la liste des informations nécessaires pour l'envoi de messages texte :",
              endpoint: "Point de terminaison : ",
              method: "Méthode : ",
              headers: "En-têtes : ",
              body: "Corps : ",
            },
            test: "Test d'envoi : ",
            mediaMsg: {
              title: "Message multimédia",
              info: "Voici la liste des informations nécessaires pour l'envoi de messages multimédia :",
              endpoint: "Point de terminaison : ",
              method: "Méthode : ",
              headers: "En-têtes : ",
              body: "Corps : ",
              formData: "FormData : ",
            },
            instructions: "Instructions",
            notes: {
              title: "Remarques importantes",
              textA: "Avant d'envoyer des messages, il est nécessaire d'enregistrer le jeton lié à la connexion qui enverra les messages. <br/>Pour effectuer l'enregistrement, accédez au menu 'Connexions', cliquez sur le bouton modifier de la connexion et insérez le jeton dans le champ correspondant.",
              textB: {
                title: "Le numéro pour l'envoi ne doit pas avoir de masque ni de caractères spéciaux et doit être composé de :",
                partA: "Code de pays",
                partB: "Code régional",
                partC: "Numéro",
              },
            },
          },
        },
        notifications: {
          noTickets: "Aucune notification.",
        },
        quickMessages: {
          title: "Réponses rapides",
          searchPlaceholder: "Rechercher...",
          noAttachment: "Aucune pièce jointe",
          confirmationModal: {
            deleteTitle: "Suppression",
            deleteMessage: "Cette action est irréversible ! Voulez-vous continuer ?",
          },
          buttons: {
            add: "Ajouter",
            attach: "Joindre un fichier",
            cancel: "Annuler",
            edit: "Modifier",
          },
          toasts: {
            success: "Raccourci ajouté avec succès !",
            deleted: "Raccourci supprimé avec succès !",
          },
          dialog: {
            title: "Réponse rapide",
            shortcode: "Raccourci",
            message: "Réponse",
            save: "Sauvegarder",
            cancel: "Annuler",
            geral: "Permettre l'édition",
            add: "Ajouter",
            edit: "Modifier",
            visao: "Permettre la vision",
            geral: "Global",
          },
          table: {
            shortcode: "Raccourci",
            message: "Message",
            actions: "Actions",
            mediaName: "Nom du fichier",
            status: "Statut",
          },
        },
        messageVariablesPicker: {
          label: "Variables disponibles",
          vars: {
            contactFirstName: "Prénom",
            contactName: "Nom",
            greeting: "Salutation",
            protocolNumber: "Numéro de protocole",
            date: "Date",
            hour: "Heure",
          },
        },
        contactLists: {
          title: "Listes de contacts",
          table: {
            name: "Nom",
            contacts: "Contacts",
            actions: "Actions",
          },
          buttons: {
            add: "Nouvelle liste",
          },
          dialog: {
            name: "Nom",
            company: "Entreprise",
            okEdit: "Modifier",
            okAdd: "Ajouter",
            add: "Ajouter",
            edit: "Modifier",
            cancel: "Annuler",
          },
          confirmationModal: {
            deleteTitle: "Supprimer",
            deleteMessage: "Cette action ne peut pas être annulée.",
          },
          toasts: {
            deleted: "Enregistrement supprimé",
          },
        },
        contactListItems: {
          title: "Contacts",
          searchPlaceholder: "Recherche",
          buttons: {
            add: "Nouveau",
            lists: "Listes",
            import: "Importer",
          },
          dialog: {
            name: "Nom",
            number: "Numéro",
            whatsapp: "Whatsapp",
            email: "Courriel",
            okEdit: "Modifier",
            okAdd: "Ajouter",
            add: "Ajouter",
            edit: "Modifier",
            cancel: "Annuler",
          },
          table: {
            name: "Nom",
            number: "Numéro",
            whatsapp: "Whatsapp",
            email: "Courriel",
            actions: "Actions",
          },
          confirmationModal: {
            deleteTitle: "Supprimer",
            deleteMessage: "Cette action ne peut pas être annulée.",
            importMessage: "Voulez-vous importer les contacts de cette feuille de calcul ?",
            importTitlte: "Importer",
          },
          toasts: {
            deleted: "Enregistrement supprimé",
          },
        },
        campaigns: {
          title: "Campagnes",
          searchPlaceholder: "Recherche",
          buttons: {
            add: "Nouvelle campagne",
            contactLists: "Listes de contacts",
          },
          table: {
            name: "Nom",
            whatsapp: "Connexion",
            contactList: "Liste de contacts",
            status: "Statut",
            scheduledAt: "Programmé",
            completedAt: "Complété",
            confirmation: "Confirmation",
            actions: "Actions",
          },
          dialog: {
            new: "Nouvelle campagne",
            update: "Modifier la campagne",
            readonly: "Lecture seule",
            form: {
              name: "Nom",
              message1: "Message 1",
              message2: "Message 2",
              message3: "Message 3",
              message4: "Message 4",
              message5: "Message 5",
              confirmationMessage1: "Message de confirmation 1",
              confirmationMessage2: "Message de confirmation 2",
              confirmationMessage3: "Message de confirmation 3",
              confirmationMessage4: "Message de confirmation 4",
              confirmationMessage5: "Message de confirmation 5",
              messagePlaceholder: "Contenu du message",
              whatsapp: "Connexion",
              status: "Statut",
              scheduledAt: "Programmé",
              confirmation: "Confirmation",
              contactList: "Liste de contacts",
              tagList: "Liste d'étiquettes",
              fileList: "Liste de fichiers",
            },
            buttons: {
              add: "Ajouter",
              edit: "Mettre à jour",
              okadd: "Ok",
              cancel: "Annuler les tirs",
              restart: "Redémarrer les tirs",
              close: "Fermer",
              attach: "Joindre un fichier",
            },
          },
          confirmationModal: {
            deleteTitle: "Supprimer",
            deleteMessage: "Cette action ne peut pas être annulée.",
          },
          toasts: {
            success: "Opération réalisée avec succès",
            cancel: "Campagne annulée",
            restart: "Campagne redémarrée",
            deleted: "Enregistrement supprimé",
          },
        },
        announcements: {
          active: 'Actif',
          inactive: 'Inactif',
          title: "Annonces",
          searchPlaceholder: "Recherche",
          buttons: {
            add: "Nouvelle annonce",
            contactLists: "Listes d'annonces",
          },
          table: {
            priority: "Priorité",
            title: "Titre",
            text: "Texte",
            mediaName: "Fichier",
            status: "Statut",
            actions: "Actions",
          },
          dialog: {
            edit: "Édition de l'annonce",
            add: "Nouvelle annonce",
            update: "Modifier l'annonce",
            readonly: "Lecture seule",
            form: {
              priority: "Priorité",
              title: "Titre",
              text: "Texte",
              mediaPath: "Fichier",
              status: "Statut",
            },
            buttons: {
              add: "Ajouter",
              edit: "Mettre à jour",
              okadd: "Ok",
              cancel: "Annuler",
              close: "Fermer",
              attach: "Joindre un fichier",
            },
          },
          confirmationModal: {
            deleteTitle: "Supprimer",
            deleteMessage: "Cette action ne peut pas être annulée.",
          },
          toasts: {
            success: "Opération réalisée avec succès",
            deleted: "Enregistrement supprimé",
          },
        },
        campaignsConfig: {
          title: "Configurations des campagnes",
        },
        notifications: {
          noTickets: "Aucune notification.",
        },
        queues: {
          title: "Files d'attente",
          table: {
            name: "Nom",
            color: "Couleur",
            greeting: "Message de salutation",
            actions: "Actions",
          },
          buttons: {
            add: "Ajouter une file d'attente",
          },
          confirmationModal: {
            deleteTitle: "Supprimer",
            deleteMessage: "Êtes-vous sûr ? Cette action ne peut pas être annulée ! Les tickets dans cette file d'attente continueront d'exister, mais ils n'auront plus aucune file d'attente assignée.",
          },
        },
        queueSelect: {
          inputLabel: "Files d'attente",
        },
        users: {
          title: "Utilisateurs",
          table: {
            name: "Nom",
            email: "Courriel",
            profile: "Profil",
            actions: "Actions",
            startWork: "Début du travail",
            endWork: "Fin du travail",
          },
          status: {
            online: "Utilisateurs en ligne",
            offline: "Utilisateurs hors ligne",
          },
          buttons: {
            add: "Ajouter un utilisateur",
          },
          toasts: {
            deleted: "Utilisateur supprimé avec succès.",
          },
          confirmationModal: {
            deleteTitle: "Supprimer",
            deleteMessage: "Toutes les informations de l'utilisateur seront perdues. Les tickets ouverts des utilisateurs seront déplacés vers la file d'attente.",
          },
        },
        compaies: {
          title: "Entreprises",
          table: {
            status: "Actif",
            name: "Nom",
            email: "Email",
            numberAttendants: "Attendants",
            numberConections: "Connexions",
            value: "Valeur",
            namePlan: "Nom du plan",
            numberQueues: "Files d'attente",
            useCampaigns: "Campagnes",
            useExternalApi: "API externe",
            useFacebook: "Facebook",
            useInstagram: "Instagram",
            useWhatsapp: "Whatsapp",
            useInternalChat: "Chat interne",
            useSchedules: "Programmations",
            createdAt: "Créé le",
            dueDate: "Date d'échéance",
            lastLogin: "Dernière connexion",
            actions: "Actions",
          },
          buttons: {
            add: "Ajouter une entreprise",
          },
          toasts: {
            deleted: "Entreprise supprimée avec succès.",
          },
          confirmationModal: {
            deleteTitle: "Supprimer",
            deleteMessage: "Toutes les données de l'entreprise seront perdues. Les tickets ouverts de cet utilisateur seront déplacés vers la file d'attente.",
          },
        },
        helps: {
          title: "Centre d'aide",
        },
        schedules: {
          title: "Programmations",
          confirmationModal: {
            deleteTitle: "Êtes-vous sûr de vouloir supprimer cette programmation ?",
            deleteMessage: "Cette action ne peut pas être annulée.",
          },
          table: {
            contact: "Contact",
            body: "Message",
            sendAt: "Date de programmation",
            sentAt: "Date d'envoi",
            status: "Statut",
            actions: "Actions",
          },
          calendar: {
            date: "Date",
            time: "Heure",
            event: "Événement",
            allDay: "Toute la journée",
            week: "Semaine",
            work_week: "Programmations",
            day: "Jour",
            month: "Mois",
            previous: "Précédent",
            next: "Suivant",
            yesterday: "Hier",
            tomorrow: "Demain",
            today: "Aujourd'hui",
            agenda: "Agenda",
            noEventsInRange: "Aucun événement dans la plage de dates.",
          },
          buttons: {
            add: "Nouvelle programmation",
          },
          toasts: {
            deleted: "Programmation supprimée avec succès.",
          },
        },
        tags: {
          title: "Étiquettes",
          confirmationModal: {
            deleteTitle: "Êtes-vous sûr de vouloir supprimer cette étiquette ?",
            deleteMessage: "Cette action ne peut pas être annulée.",
          },
          table: {
            name: "Nom",
            color: "Couleur",
            tickets: "Tickets étiquetés",
            actions: "Actions",
          },
          buttons: {
            add: "Nouvelle étiquette",
          },
          toasts: {
            deleted: "Étiquette supprimée avec succès.",
          },
        },
        settings: {
          success: "Paramètres sauvegardés avec succès.",
          title: "Paramètres",
          settings: {
            userCreation: {
              name: "Création d'utilisateur",
              options: {
                enabled: "Activé",
                disabled: "Désactivé",
              },
            },
            tabs: {
              options: "Options",
              schedules: "Horaires",
              companies: "Entreprises",
              plans: "Plans",
              helps: "Aide",
            },
          },
        },
        messagesList: {
          header: {
            assignedTo: "Attribué à :",
            buttons: {
              return: "Retourner",
              resolve: "Résoudre",
              reopen: "Rouvrir",
              accept: "Accepter",
            },
          },
        },
        messagesInput: {
          placeholderOpen: "Écrivez un message",
          placeholderClosed: "Rouvrez ou acceptez ce ticket pour envoyer un message.",
          signMessage: "Signer",
        },
        message: {
          edited: "Édité",
          deleted: "Message supprimé",
        },
        contactDrawer: {
          header: "Détails du contact",
          buttons: {
            edit: "Modifier le contact",
          },
          extraInfo: "Autres informations",
        },
        fileModal: {
          title: {
            add: "Ajouter une liste de fichiers",
            edit: "Modifier une liste de fichiers",
          },
          buttons: {
            okAdd: "Sauvegarder",
            okEdit: "Modifier",
            cancel: "Annuler",
            fileOptions: "Ajouter un fichier",
          },
          form: {
            name: "Nom de la liste de fichiers",
            message: "Détails de la liste",
            fileOptions: "Liste de fichiers",
            extraName: "Message à envoyer avec le fichier",
            extraValue: "Valeur de l'option",
          },
          success: "Liste de fichiers sauvegardée avec succès !",
        },
        ticketOptionsMenu: {
          schedule: "Programmation",
          delete: "Supprimer",
          transfer: "Transférer",
          registerAppointment: "Observations du contact",
          appointmentsModal: {
            title: "Observations du contact",
            textarea: "Observation",
            placeholder: "Entrez ici l'information que vous souhaitez enregistrer",
          },
        },
        confirmationModal: {
          buttons: {
            confirm: "Ok",
            cancel: "Annuler",
          },
        },
        messageOptionsMenu: {
          delete: "Supprimer",
          reply: "Répondre",
          history: "Historique",
          edit: "Modifier",
          confirmationModal: {
            title: "Supprimer le message ?",
            message: "Cette action ne peut pas être annulée.",
          },
          forward: "Sélectionnez pour transférer",
          forwardbutton: "TRANSFÉRER",
          forwardmsg1: "Transférer le message",
        },
        inputErrors: {
          tooShort: "Trop court",
          tooLong: "Trop long",
          required: "Obligatoire",
          email: "Adresse courriel invalide",
        },
        backendErrors: {
          ERR_NO_OTHER_WHATSAPP: "Il doit y avoir au moins un WhatsApp par défaut.",
          ERR_NO_DEF_WAPP_FOUND: "Aucun WhatsApp par défaut trouvé. Vérifiez la page de connexions.",
          ERR_WAPP_NOT_INITIALIZED: "Cette session WhatsApp n'a pas été initialisée. Vérifiez la page de connexions.",
          ERR_WAPP_CHECK_CONTACT: "Impossible de vérifier le contact WhatsApp. Vérifiez la page de connexions.",
          ERR_WAPP_INVALID_CONTACT: "Ce n'est pas un numéro de WhatsApp valide.",
          ERR_WAPP_DOWNLOAD_MEDIA: "Impossible de télécharger les médias WhatsApp. Vérifiez la page de connexions.",
          ERR_INVALID_CREDENTIALS: "Erreur d'authentification. Veuillez réessayer.",
          ERR_SENDING_WAPP_MSG: "Erreur lors de l'envoi du message WhatsApp. Vérifiez la page de connexions.",
          ERR_DELETE_WAPP_MSG: "Impossible de supprimer le message WhatsApp.",
          ERR_OTHER_OPEN_TICKET: "Il existe déjà un ticket ouvert pour ce contact.",
          ERR_SESSION_EXPIRED: "Session expirée. Veuillez vous connecter.",
          ERR_USER_CREATION_DISABLED: "La création d'utilisateur a été désactivée par l'administrateur.",
          ERR_NO_PERMISSION: "Vous n'avez pas la permission d'accéder à cette ressource.",
          ERR_DUPLICATED_CONTACT: "Il existe déjà un contact avec ce numéro.",
          ERR_NO_SETTING_FOUND: "Aucun paramètre trouvé avec cet ID.",
          ERR_NO_CONTACT_FOUND: "Aucun contact trouvé avec cet ID.",
          ERR_NO_TICKET_FOUND: "Aucun ticket trouvé avec cet ID.",
          ERR_NO_USER_FOUND: "Aucun utilisateur trouvé avec cet ID.",
          ERR_NO_USER_DELETE: "Impossible de supprimer l'utilisateur Super",
          ERR_NO_WAPP_FOUND: "Aucun WhatsApp trouvé avec cet ID.",
          ERR_CREATING_MESSAGE: "Erreur lors de la création du message dans la base de données.",
          ERR_CREATING_TICKET: "Erreur lors de la création du ticket dans la base de données.",
          ERR_FETCH_WAPP_MSG: "Erreur lors de la récupération du message sur WhatsApp, il est peut-être trop ancien.",
          ERR_QUEUE_COLOR_ALREADY_EXISTS: "Cette couleur est déjà utilisée, veuillez en choisir une autre.",
          ERR_WAPP_GREETING_REQUIRED: "Le message de salutation est obligatoire lorsqu'il y a plus d'une file d'attente.",
          ERR_EDITING_WAPP_MSG: "Impossible d'éditer le message",
        },
      },
    },
  };
  
  export { messages };
  